import { Injectable, inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
      private auth = inject(Auth);

  async auditDoc(doc: any) {
    const who = await firstValueFrom(user(this.auth));
    // If there is not createdAt, thie is an addition; except, when
    // some filemaker records were missing a created timestamp.
    // We don't want to override it.
    const hasCreated = doc.createdAt || doc.createdBy;
    if (!hasCreated) {
      Object.assign(doc, {
        createdBy: who?.email,
        createdUid: who?.uid,
        createdAt: dayjs().unix(),
      });
    }
    Object.assign(doc, {
      modifiedBy: who?.email,
      modifiedUid: who?.uid,
      modifiedAt: dayjs().unix(),
    });
    return doc;
  }

  async mutate(original: any, doc: any) {
    return this.auditDoc(
      Object.assign(doc, {
        createdBy: original.createdBy ?? null,
        createdUid: original.createdUid ?? null,
        createdAt: original.createdAt ?? null,
      })
    );
  }
}
